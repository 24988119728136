html,
body {
  height: 100% !important;
}

#root { /* stylelint-disable-line selector-max-id */
  height: 100%;
  display: flex;
  flex-direction: column;
}

.page {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 0;
  flex: 1;

  &-header {
    padding: 60px 60px 0;
    z-index: 15;

    &--shadow {
      box-shadow: 0px 1px 25px -14px rgba(0, 0, 0, 0.6);
    }

    @include media-breakpoint-down(lg, $breakpoints) {
      padding: 48px 48px 0;
    }
    @include media-breakpoint-down(sm, $breakpoints) {
      padding: 6px 16px 0;
    }
    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 16px;
      @include media-breakpoint-down(sm, $breakpoints) {
        padding-bottom: 8px;
      }
    }
    &__logo, &__service-logo {
      display: flex;
      justify-content: flex-start;
      width: 120px;
      height: 30px;
  
      img {
        display: block;
        object-fit: contain;
        height: 100%;
      }
    }
    &__logo {
      cursor: pointer;
    }
    &__service-logo {
      height: 40px;
      display: flex;
      justify-content: flex-end;

      img {
        width: auto;
      }
    }

    &-row {
      padding: 24px 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 16px;
  
      @include media-breakpoint-down(sm, $breakpoints) {
        padding-top: 12px;
        padding-bottom: 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
      }

      &-title {
        flex: 1;
      }
      &__title {
        flex: 1;

        @include media-breakpoint-down(sm, $breakpoints) {
          font-size: 24px;
        }
      }
      &__description {
        margin-top: 8px;
        font-size: 16px;
        max-width: 900px;
      }
      .stepper {
        width: 316px;

        @include media-breakpoint-down(sm, $breakpoints) {
            width: 100%;
        }

        &__label {
            margin-bottom: 2px;
            text-align: right;
            font-size: 12px;
            color: $gray-600;

            &--complete {
              font-weight: 600;
            }
        }
        &__bar {
            position: relative;
            height: 10px;
            width: 100%;
            border-radius: 5px;
            background-color: $gray-200;
        }
        &__progress {
            position: absolute;
            left: 0;
            height: 100%;
            z-index: 1;
            border-radius: 5px;
            background-color: $green;
        }
    }
    }
  }

  &-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  &-main {
    padding: 0 60px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    // -webkit-overflow-scrolling: touch;
    @extend .custom-scroll;
    gap: 24px;

    @include media-breakpoint-down(lg, $breakpoints) {
      padding: 0 48px;
    }
    @include media-breakpoint-down(sm, $breakpoints) {
      padding: 0 16px;
    }
  }

  &-footer {
    padding: 24px 60px;
    display: flex;
    align-items: center;
    gap: 8px;
    z-index: 15;

    &--shadow {
      box-shadow: 0px 0px 25px -14px rgba(0, 0, 0, 0.6);
    }

    @include media-breakpoint-down(sm, $breakpoints) {
      // justify-content: space-between;
    }
    @include media-breakpoint-down(lg, $breakpoints) {
      padding: 24px 48px;
    }
    @include media-breakpoint-down(sm, $breakpoints) {
      padding: 16px 16px;
      padding-bottom: 30px;
    }
  }
}

.layout {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 0;
  flex: 1;
  @include media-breakpoint-up(lg, $breakpoints-drawer) {
    &__with-drawer {
      max-width: calc(100% - #{$drawer-width});
    }
  }

  @include media-breakpoint-up(xl, $breakpoints-drawer) {
    font-size: inherit;
  }

  main {
    display: flex;
    min-height: 0;
    flex: 1;
  }
}

.content {
  display: flex;
  flex-direction: column;
  min-height: 0;
  min-width: 0;
  flex: 1;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    flex: 0 0 69px;
    white-space: nowrap;

    &__row {
      &-container {
        display: flex;
        flex-direction: column;
        border-bottom: $border-width solid $border-color;
      }
    }

    &__low-static {
      padding: 0 20px 11px;
      display: flex;
      flex-direction: column;
    }

    &__height-auto {
      flex: 1;
      padding: 20px 20px 13px;
    }

    &-left {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
    }

    &-right {
      display: flex;
      justify-content: right;
      flex: none;

      &--static {
        margin-left: 10px;
      }
    }

    &__back-route {
      flex: 1 1 100%;
      margin-bottom: 4px;

      &--btn {
        background-color: transparent;
        border: 0;

        .icons {
          margin-right: 8px;
        }
      }
    }
  }

  &-title {
    margin-right: 16px;
    display: flex;
    align-items: center;

    h1, h2, h3,
    .h1, .h2, .h3 {
      font-weight: $font-weight-semi-bold;
      line-height: 1em;
      margin-bottom: 0;
    }

    h1 + * {
      margin-left: 10px;
    }
  }

  &-actions {
    margin: -6px 0;
    display: flex;
    align-items: center;

    & > * {
      margin-left: 8px;
    }

    @include media-breakpoint-down(sm, $breakpoints) {
      & > * {
        margin: 0;
      }
    }

    .btn + .btn {
      margin-left: 6px;
    }

    &--sort {
      display: flex;
      align-items: center;
      flex: 1;

      .icon-container {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 0 4px 0 -4px;
        font-size: 16px;

        @include size(16px);
      }
    }

    &--title {
      font-weight: $font-weight-semi-bold;
      letter-spacing: -0.5px;
    }

    .filter__toggle {
      .content-actions--sort {
        margin-right: 0;
      }
    }
  }

  &-center {
    display: flex;
    align-items: center;
  }

  &-scroll {
    overflow-x: hidden;
    flex: 1;

    @extend .custom-scroll;
  }

  &-table {
    overflow: auto;
    min-height: 0;
    flex: 1;

    @extend .custom-scroll;

    > .table {
      margin-bottom: 0;
      min-width: 800px;
    }
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: $border-width solid $border-color;
    flex: 0 0 71px;

    &-pages {
      color: $gray-400;
      font-weight: 700;
      font-size: 12px;
      padding: 20px;
    }

    &-pagination {
      padding: 20px;

      .pagination {
        margin-bottom: 0;
      }
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  flex: 0 0 64px;
  padding: 0 20px;
  border-bottom: $border-width solid $border-color;
  background-color: #fff;

  &__left {
    display: flex;
  }

  &__right {
    display: inline-flex;
    align-items: center;

    & > div {
    width: 335px;

    }

    .form-custom-select__value-container {
      margin-right: 8px;

      .aside__actions--location-option-address {
        display: none;
      }
    }
  }

  &__bar {
    display: flex;
    padding: 10px 18px;


    .btn {
      padding: 5px 10px;
      font-size: 23px;
      height: 44px;
      position: relative;

      &:hover {
        background-color: theme-primary("400");
      }
    }

    &--badge {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: theme-color("danger");
      border-radius: 16px;
      line-height: 10px;
      padding: 1px 5px;
      font-weight: 700;
      font-size: 10px;
      min-width: 16px;
      color: #fff;
      height: 16px;

      @include position(absolute, 0 0 auto auto);
    }
  }

  &__avatar {
    display: flex;
    padding: 0 18px;
    margin: auto 0;

    .avatar {
      flex: none;
    }
  }

  @include media-breakpoint-down(md, $breakpoints-menu) {
    display: flex;
  }
}

.mb-0 {
  margin-bottom: 0;
}
.mb-sm {
  margin-bottom: 4px;
}
.mt-0 {
  margin-top: 0;
}

.flex-1 {
  flex: 1;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.gap-sm {
  gap: $spacer * 0.25;
}

.gap-md {
  gap: $spacer * 0.5;
}

.gap-m {
  gap: $spacer;
}
