.auth {
  padding-bottom: 70px;
  padding-top: 130px;

  &-container {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &-logo {
    margin-bottom: 48px;
    text-align: center;

    img {
      max-height: 75px;
      max-width: 200px;
    }
  }

  &-title {
    margin-bottom: 20px;
    text-align: center;
  }

  &-content {
    max-width: 295px;
    margin: 0 auto;

    @include media-breakpoint-down(sm, $breakpoints) {
      max-width: 100%;
      padding: 0 12px;
    }

    &__on-boarding {
      max-width: 401px;

      &--logo {
        margin-bottom: 16px;
        text-align: center;

        img {
          height: 75px;
        }
      }

      &--title {
        margin-bottom: 16px;
        text-align: center;
        font-size: $font-size-l;
        letter-spacing: -0.41px;
        color: $gray-600;
      }

      &--icon {
        font-size: 41px;
        margin-bottom: 16px;
        margin-top: -32px;
        text-align: center;
        color: $success;
      }

      &--banner {
        margin-bottom: 16px;
      }

      &--form-container {
        padding: 16px;
        margin-bottom: 16px;
        border: $border;
        border-radius: $border-radius;
      }

      &--welcome {
        font-size: 32px;
        margin: -32px auto 16px;
        text-align: center;
      }

      &--resend {
        display: flex;
        align-items: center;
        justify-content: center;

        .btn {
          margin-left: 4px;
        }
      }
    }

    .btn-block {
      width: 100%;
    }
  }

  &-footer {
    text-align: center;
    padding-bottom: 25px;

    &__logo {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 35px;
        margin-left: 8px;
      }
    }
  }

  &-sign-in-switch {
    padding-left: 0;

    label {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }

  &-description {
    color: $gray-400;
    text-align: center;
    margin-bottom: 24px;

    p {
      margin-bottom: 0;

      & + p {
        margin-top: $paragraph-margin-bottom;
      }
    }
  }

  &-resend-code {
    margin-bottom: 24px;

    .resend-title {
      font-size: 14px;
      cursor: pointer;
      color: theme-primary();
    }

    .resend-loading {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &-phone {
    color: $black;
    font-size: 18px;
  }

  &-email {
    color: $black;
    font-size: 18px;
    overflow-wrap: break-word;

    @include media(">sm") {
      display: flex;
      justify-content: center;
      white-space: nowrap;
    }
  }

  &-recovery-code {
    cursor: pointer;
    margin-bottom: 24px;
    font-size: $h3-font-size;
    color: theme-primary();
    font-weight: $font-weight-bold;
    text-align: center;

    @include media(">sm") {
      display: flex;
      justify-content: center;
      white-space: nowrap;
    }
  }

  &-button-icon {
    .icons {
      @include size(20px);
      margin-left: 5px;
    }
  }

  &-wrap-checks {
    margin-bottom: 16px;
  }

  &-wrap-check {
    display: flex;
    align-items: center;

    .icons {
      font-size: 22px;
      margin-right: 8px;
    }

    & + .auth-wrap-check {
      margin-top: 6px;
    }
  }

  &-input-group-password {
    isolation: isolate;

    .input-group-append {
      right: 5px;
      margin-top: 0;
    }

    button:hover {
      background-color: $gray-200;
    }
  }

  .form-group > .form-control:not(:last-child) {
    padding-right: 35px;
  }

  .form-group {
    margin-bottom: 16px;
  }
}
