.custom-scroll {
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    @include size(5px);
  }

  &::-webkit-scrollbar-thumb {
    background-color: $border-color;
    border-radius: 20px;
  }
}
