.sign-out {
  &__btn {
    font-size: 20px;
  }

  &__dropdown-menu {
    .dropdown-item {
      height: 32px;

      &:focus, &:active {
        background-color: unset;
        color: inherit;
      }

      &:hover {
        color: inherit;
      }
    }

    &--indicator {
      font-size: 16px;
      color: $gray-700;
      margin-left: 5px;
      margin-right: -3px;
    }

    &-filters {
      > .dropdown-item {
        padding: 0;

        &:focus, &:active, &:hover {
          background-color: unset;
        }

        .filter {
          width: 100%;

          &__dropdown-menu--indicator {
            margin-right: 5px;
            margin-left: -3px;
          }
        }

        .filter__toggle.btn-secondary {
          border: unset;
          width: 100%;
        }
      }
    }
  }

  &__toggle:not(:disabled):not(.disabled):active {
    background-color: unset;
  }

  & > &__toggle.btn-secondary {
    display: flex;
    align-items: center;
    padding: 3px 10px;
    line-height: 1.25;
    font-size: 14px;
    font-weight: $font-weight-semi-bold;
    border: unset;
    height: 32px;
    box-shadow: none;
    background-color: unset;
    letter-spacing: -0.5px;
  }

  .show > &__toggle.btn-secondary {
    box-shadow: none;
  }

  &__divider-container {
    padding: 1px 0;
  }

  &__value {
    display: flex;
    align-items: center;

    &--title {
      font-size: $font-size-m;
      font-weight: $font-weight-semi-bold;
    }

    .avatar {
      margin-right: 8px;
    }
  }
}

