//
// Basic Bootstrap table
//

.table {
  width: 100%;
  background-color: $table-bg;
  margin-bottom: $spacer;
  color: $table-color;

  // all table interactive element
  tr, th, td {
    &[role="presentation"] {
      cursor: pointer;
    }
  }

  a {
    color: $gray-600;
    &:hover {
      text-decoration: underline;
    }
  }

  th,
  td {
    border-bottom: $table-border-width solid $table-border-color;
    padding: $table-cell-padding;
    vertical-align: middle;
    min-height: 48px;

    &:first-child {
      padding-left: $table-cell-padding * 1.18;
    }

    &:last-child {
      padding-right: $table-cell-padding * 1;
    }

    &.cell-checkbox {
      width: 40px;
      text-align: center;

      input {
        cursor: pointer;
      }
    }

    &.cell-actions {
      padding-bottom: $table-cell-padding / 2;
      padding-top: $table-cell-padding / 2;
      text-align: right;
      width: 56px;
    }

    &.cell-not-found {
      border-bottom: none;
      background-color: transparent;
      pointer-events: none;
      text-align: center;
    }

    &.cell-no-wrap {
      white-space: nowrap;
    }
  }

  thead th {
    border-bottom: $table-border-width solid $table-border-color;
    font-weight: $table-th-font-weight;
    background-color: $table-head-bg;
    text-transform: capitalize;
    color: $table-head-color;
    vertical-align: bottom;
    border-top: none;
    font-size: 12px;
    height: 32px;
  }

  tbody + tbody {
    border-top: (2 * $table-border-width) solid $table-border-color;
  }

  &-header {
    &__selected {
      &--container {
        display: flex;
        align-items: center;

        .btn-secondary {
          &:focus, &.focus {
            background-color: theme-primary("300");
            color: theme-primary();
            border-color: transparent;
            box-shadow: none;
          }

          &:not(:disabled):not(.disabled):active,
          &:not(:disabled):not(.disabled):active,
          &:hover {
            background-color: $dropdown-link-hover-bg;
            color: $dropdown-link-hover-color;
            box-shadow: none;
            border-color: transparent;
          }

          &.disabled, &:disabled {
            color: $dropdown-link-disabled-color;
            background-color: unset;
          }
        }

        .show > .btn-secondary.dropdown-toggle {
          background-color: $dropdown-link-hover-bg;
          color: $dropdown-link-hover-color;
          box-shadow: none;
          border-color: transparent;
        }
      }

      &--title {
        text-transform: capitalize;
        color: $gray-400;
        flex: none;
      }

      &--action {
        &-container {
          flex: 1;
          display: flex;
        }
      }

      &--button {
        color: theme-primary();
        background-color: unset;
        border-color: transparent;
        box-shadow: none;
        margin: -3px 0;

        &-container {
          padding: 0 10px;
        }

        &:hover, &:focus, &:active {
          text-decoration: none;
        }

        &:hover, &:active {
          background-color: theme-primary("400");
          color: theme-primary("600");
        }

        &:disabled {
          color: $dropdown-link-disabled-color;
          background-color: unset;
        }
      }
    }

    &__sort {
      &-asc:hover, &-desc:hover {
        cursor: pointer;

        &::after {
          opacity: 1;
        }
      }

      &-asc::after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0;
        border-right: 0.3em solid transparent;
        border-bottom: 0.3em solid;
        border-left: 0.3em solid transparent;
        opacity: 0;
      }

      &-desc::after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.155em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
        opacity: 0;
      }

      &-asc, &-desc {
        &--selected {
          &::after {
            opacity: 1;
          }
        }
      }
    }
  }
}

.table-sm {
  th,
  td {
    padding: $table-cell-padding-sm;
  }
}

// Border versions
//
// Add or remove borders all around the table and between all the columns.

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }

  thead {
    th,
    td {
      border-bottom-width: 2 * $table-border-width;
    }
  }
}

.table-borderless {
  th,
  td,
  thead th,
  tbody + tbody {
    border: 0;
  }

  tr {
    background-color: unset !important;
  }
}

// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-striped {
  tbody tr:nth-of-type(#{$table-striped-order}) {
    background-color: $table-accent-bg;
  }
}

// Hover effect
//
// Placed here since it has to come after the potential zebra striping
.table-hover {
  tbody tr {
    @include hover {
      color: $table-hover-color;
      background-color: $table-hover-bg;
    }


    &.selected {
      color: $table-hover-color;
      background-color: $table-hover-bg;

      td:first-child {

        &::before {
          background-color: theme-color();
        }
      }
    }
  }

  tbody td {
    &:first-child {
      position: relative;

      &::before {
        content: '';
        background-color: transparent;

        @include position(absolute, 0 null null 0);
        @include size(5px, 100%);
      }
    }
  }
}

// Responsive tables
//
// Generate series of `.table-responsive-*` classes for configuring the screen
// size of where your table will overflow.

.table-responsive {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        // Prevent double border on horizontal scroll due to use of `display: block;`
        > .table-bordered {
          border: 0;
        }
      }
    }
  }
}

.not-found__container {
  background-color: $gray-200;
  color: $gray-700;
  margin: 40px auto 0;
  padding: 30px;
  text-align: center;
  max-width: 70%;

  button, .btn {
    margin-top: 10px;
  }
}
