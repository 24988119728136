.filter {
  &__dropdown-menu {
    .dropdown-item {
      height: 32px;

      input[type="checkbox"] {
        cursor: pointer;
        margin-right: 10px;
      }

      &:focus, &:active {
        background-color: unset;
        color: inherit;
      }

      &:hover {
        background-color: $gray-200;
        color: inherit;
      }

      &--checked-icon {
        margin-left: 8px;
      }
    }

    &--indicator {
      font-size: 16px;
      color: $gray-400;
      margin-left: 5px;
      margin-right: -3px;
    }

    &-filters {
      > .dropdown-item {
        padding: 0;

        &:focus, &:active, &:hover {
          background-color: unset;
        }

        .filter {
          width: 100%;

          &__dropdown-menu--indicator {
            margin-right: 5px;
            margin-left: -3px;
          }
        }

        .filter__toggle.btn-secondary {
          border: unset;
          width: 100%;
        }
      }
    }
  }

  & > &__toggle.btn-secondary {
    display: flex;
    align-items: center;
    padding: 3px 10px;
    line-height: 1.25;
    font-size: 14px;
    font-weight: $font-weight-semi-bold;
    border-radius: $border-radius;
    border: $border;
    height: 32px;
    box-shadow: none;
    background-color: unset;
    letter-spacing: -0.5px;

    .filter__toggle-count {
      flex: 0 0 auto;
      text-align: center;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: theme-color();
      border-radius: 16px;
      line-height: 10px;
      padding: 1px 5px;
      font-weight: 700;
      font-size: 12px;
      min-width: 20px;
      color: #fff;
      height: 20px;

      &-container {
        margin-left: 5px;
      }
    }
  }

  .show > &__toggle.btn-secondary {
    box-shadow: none;
    color: $white;
    background-color: $gray-500;

    .filter__toggle-count {
      background-color: $white;
      color: $gray-500;
    }
  }

  &__divider-container {
    padding: 1px 0;
  }

  &__item-color {
    &--blue {
      color: $blue;
    }

    &--green {
      color: $green;
    }

    &--orange {
      color: $orange;
    }

    &--red {
      color: $red;
    }
  }
}
