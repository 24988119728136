// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and stuff

.modal-open {
  // Kill the scroll on the body
  overflow: hidden;

  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.modal {
  display: none;
  overflow: hidden;
  z-index: $zindex-modal;
  outline: 0;

  @include position(fixed, 0 null null 0);
  @include size(100%);
}

.modal-dialog {
  width: auto;
  position: relative;
  margin: $modal-dialog-margin;

  // allow clicks to pass through for custom click handling to close modal
  pointer-events: none;

  // When fading in the modal, animate it to slide down
  .modal.fade & {
    @include transition($modal-transition);

    transform: $modal-fade-transform;
  }

  .modal.show & {
    transform: $modal-show-transform;
  }

  // When trying to close, animate focus to scale
  .modal.modal-static & {
    transform: $modal-scale-transform;
  }
}

.modal-dialog-scrollable {
  display: flex; // IE10/11
  max-height: subtract(100%, $modal-dialog-margin * 2);

  .modal-content {
    max-height: subtract(100vh, $modal-dialog-margin * 2); // IE10/11
    overflow: hidden;
  }

  .modal-header,
  .modal-footer {
    flex-shrink: 0;
  }

  .modal-body {
    overflow-y: auto;
  }
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: subtract(100%, $modal-dialog-margin * 2);

  // Ensure `modal-dialog-centered` extends the full height of the view (IE10/11)
  &::before {
    display: block; // IE10
    height: subtract(100vh, $modal-dialog-margin * 2);
    height: min-content; // Reset height to 0 except on IE
    content: "";
  }

  // Ensure `.modal-body` shows scrollbar (IE10/11)
  &.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .modal-content {
      max-height: none;
    }

    &::before {
      content: none;
    }
  }
}

// Actual modal
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
  // counteract the pointer-events: none; in the .modal-dialog
  color: $modal-content-color;
  pointer-events: auto;
  background-color: $modal-content-bg;
  background-clip: padding-box;
  border: $modal-content-border-width solid $modal-content-border-color;
  box-shadow: $modal-content-box-shadow-xs;

  @include border-radius($modal-content-border-radius);

  // @include box-shadow($modal-content-box-shadow-xs);
  // Remove focus outline from opened modal
  outline: 0;
}

// Modal background
.modal-backdrop {
  z-index: $zindex-modal-backdrop;
  background-color: $modal-backdrop-bg;

  @include position(fixed, 0 null null 0);
  @include size(100vw, 100vh);

  // Fade for backdrop
  &.fade {
    opacity: 0;
  }

  &.show {
    opacity: $modal-backdrop-opacity;
  }
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: $modal-header-padding;
  border-bottom: $modal-header-border-width solid $modal-header-border-color;

  @include border-top-radius($modal-content-inner-border-radius);

  .close {
    background-color: transparent;
    outline: none;
    border: 0;
    padding: 3px 2px 0;
    margin-right: -2px;
    font-size: 24px;
    line-height: 18px;
    font-weight: 300;

    // auto on the left force icon to the right even when there is no .modal-title
    //margin: (-$modal-header-padding-y) (-$modal-header-padding-x) (-$modal-header-padding-y) auto;
  }

  + .modal-body, + form .modal-body {
    padding-top: 0;
  }
}

// Title text within header
.modal-title {
  margin-bottom: 0;
  line-height: $modal-title-line-height;

  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0;
  }
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  position: relative;

  // Enable `flex-grow: 1` so that the body take up as much space as possible
  // when there should be a fixed height on `.modal-dialog`.
  flex: 1 1 auto;
  padding: $modal-inner-padding;

  + .modal-footer, + form .modal-footer {
    padding-top: 0;
  }
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: $modal-inner-padding - $modal-footer-margin-between / 2;
  border-top: $modal-footer-border-width solid $modal-footer-border-color;

  @include border-bottom-radius($modal-content-inner-border-radius);

  > * {
    margin: $modal-footer-margin-between / 2;
  }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  overflow: scroll;

  @include position(absolute, -9999px null null null);
  @include size(50px);
}

@include media-breakpoint-up(sm) {
  // Automatically set modal's width for larger viewports
  .modal-dialog {
    max-width: $modal-md;
    margin: $modal-dialog-margin-y-sm-up auto;
  }

  .modal-dialog-scrollable {
    max-height: subtract(100%, $modal-dialog-margin-y-sm-up * 2);

    .modal-content {
      max-height: subtract(100vh, $modal-dialog-margin-y-sm-up * 2);
    }
  }

  .modal-dialog-centered {
    min-height: subtract(100%, $modal-dialog-margin-y-sm-up * 2);

    &::before {
      height: subtract(100vh, $modal-dialog-margin-y-sm-up * 2);
      height: min-content;
    }
  }

  .modal-content {
    box-shadow: $modal-content-box-shadow-sm-up;
  }

  .modal-sm {
    max-width: $modal-sm;
  }
}


@include media-breakpoint-up(lg) {
  .modal-lg,
  .modal-xl {
    max-width: $modal-lg;
  }
}


@include media-breakpoint-up(xl) {
  .modal-xl {
    max-width: $modal-xl;
  }
}

.reactstrap-confirm {
  .close {
    display: none;
  }
}
