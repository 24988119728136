.status-badge {
  padding: 6px 8px;
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  font-weight: $font-weight-normal;

  & > *:not(.icons) {
    line-height: 1.41;
    color: $gray-700;
  }

  .icons {
    margin-right: 6px;
    font-size: 13px;
  }
}
