@mixin badge-variant($colors) {
  .icons {
    color: map-get($colors, "color");
    margin-right: 4px;
  }
  background-color: map-get($colors, "bg");

  //@at-root a#{&} {
  //  @include hover-focus() {
  //    color: map-get($colors, "color");
  //    background-color: darken($colors, 10%);
  //  }
  //
  //  &:focus,
  //  &.focus {
  //    outline: 0;
  //    box-shadow: 0 0 0 $badge-focus-width rgba($colors, .5);
  //  }
  //}
}
