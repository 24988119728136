// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant(
  $background,
  $border,
  $color: $body-color,
  $hover-background: $background,
  $hover-border: $border,
  $active-background: shade($background, 15%),
  $active-border: shade($border, 15%)
) {
  @if $enable-shadows {
    box-shadow: 0 2px 0 shade($background, 15%);
  }
  color: color-yiq($background, $color);
  background-color: $background;
  border-color: $border;

  @include hover {
    @if $enable-shadows {
      box-shadow: 0 3px 0 shade($background, 15%);
    }
    color: color-yiq($hover-background, $color);
    background-color: $hover-background;
    border-color: $hover-border;
  }

  &:focus,
  &.focus {
    color: color-yiq($hover-background, $color);
    background-color: $hover-background;
    border-color: $hover-border;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    color: color-yiq($background, $color);
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: color-yiq($active-background, $color);
    @if $enable-shadows and $btn-active-box-shadow != none {
      box-shadow: 0 2px 0 $active-background;
    }
    background-color: $active-background;
    border-color: $active-border;
  }
}

@mixin button-variant-primary(
  $background,
  $border,
  $color,
  $hover-background,
) {
  @if $enable-shadows {
    box-shadow: 0 2px 0 $hover-background;
  }
  background-color: $background;
  border-color: $border;
  color: $color;

  @include hover {
    @if $enable-shadows {
      box-shadow: 0 3px 0 $hover-background;
      background-color: $background;
    }
    @else {
      background-color: $hover-background;
    }
    border-color: $background;
    color: $color;
  }

  &:focus,
  &.focus {
    background-color: $background;
    border-color: $background;
    color: $color;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    box-shadow: none;
    color: $color;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    @if $enable-shadows and $btn-active-box-shadow != none {
      box-shadow: 0 2px 0 $hover-background;
    }
    background-color: $hover-background;
    border-color: $hover-background;
    color: $color;
  }
}

@mixin button-outline-variant($color, $color-hover: color-yiq($color), $active-background: $color, $active-border: $color) {
  color: $color;
  border-color: $color;

  @include hover() {
    color: $color-hover;
    background-color: $active-background;
    border-color: $active-border;
  }

  &:focus,
  &.focus {
    @if $enable-shadows {
      box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
    }
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: color-yiq($active-background);
    background-color: $active-background;
    border-color: $active-border;

    &:focus {
      @if $enable-shadows and $btn-active-box-shadow != none {
        @include box-shadow($btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, .5));
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        //box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
      }
    }
  }
}

@mixin button-outline-variant-primary(
  $color,
  $color-hover,
  $color-background-active
) {
  color: $color;
  border-color: $color;

  @include hover() {
    color: $color-hover;
    background-color: $color;
    border-color: $color;
  }

  &:focus,
  &.focus {
    @if $enable-shadows {
      box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
    }
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
    border-color: inherit !important;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $color-hover;
    background-color: $color-background-active;
    border-color: $color-background-active;

    &:focus {
      @if $enable-shadows and $btn-active-box-shadow != none {
        @include box-shadow($btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, .5));
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        //box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
      }
    }
  }
}

// Button sizes
@mixin button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
  padding: $padding-y $padding-x;
  line-height: $line-height;
  font-size: $font-size;

  // Manually declare to provide an override to the browser default
  border-radius: $border-radius;
}
