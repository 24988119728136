.toast {
  padding: 0 16px 0 8px;
  min-height: 40px;
  align-items: center;
  display: flex;

  .Toastify {
    &__toast-body {
      padding: 10px 8px;
    }

    &__close-button {
      display: flex;
      align-items: center;
      align-self: unset;
    }
  }

  &__color {
    @each $color, $value in $theme-colors {
      @if $color == danger {
        &-error {
          background-color: $red-light !important;
        }
      }
      @if $color == warning {
        &-warn {
          background-color: $orange-light !important;
        }
      }
      @if $color == success {
        &-success {
          background-color: $green-light !important;
        }
      }
      @if $color == info {
        &-info {
          background-color: $blue-light !important;
        }
      }
    }
  }
}
