.form-select {
  display: block;
  width: 100%;
  padding: $form-select-padding-y $form-select-indicator-padding $form-select-padding-y $form-select-padding-x;

  // stylelint-disable-next-line property-no-vendor-prefix
  -moz-padding-start: subtract($form-select-padding-x, 3px); // See https://github.com/twbs/bootstrap/issues/32636
  font-family: $form-select-font-family;
  font-size: $form-select-font-size;
  font-weight: $form-select-font-weight;
  line-height: $form-select-line-height;
  color: $form-select-color;
  background-color: $form-select-bg;
  background-image: escape-svg($form-select-indicator);
  background-repeat: no-repeat;
  background-position: $form-select-bg-position;
  background-size: $form-select-bg-size;
  border: $form-select-border-width solid $form-select-border-color;

  @include border-radius($form-select-border-radius, 0);
  @include transition($form-select-transition);

  appearance: none;

  &:focus {
    border-color: $form-select-focus-border-color;
    outline: 0;
  }

  &[multiple],
  &[size]:not([size="1"]) {
    padding-right: $form-select-padding-x;
    background-image: none;
  }

  &:disabled {
    background-color: $form-select-disabled-bg;
    opacity: $input-disabled-opacity;
  }

  // Remove outline from select box in FF
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $form-select-color;
  }
}

.form-select-lg {
  padding-top: $form-select-padding-y-lg;
  padding-bottom: $form-select-padding-y-lg;
  padding-left: $form-select-padding-x-lg;
  font-size: $form-select-font-size-lg;
}

.form-custom-select,
.form-custom-select-creatable {
  width: 100%;

  &__control {
    border: $input-border-width solid $input-border-color !important;
    font-family: $input-font-family !important;
    font-weight: $input-font-weight !important;
    line-height: $input-line-height !important;
    background-clip: padding-box !important;
    background-color: $input-bg !important;
    font-size: $input-font-size !important;
    min-height: $input-height-lg;
    color: $input-color !important;
    box-shadow: none !important;

    @include border-radius($input-border-radius !important, 0);

    &--is-focused {
      border-color: var(--primary) !important;
    }

    &--prepend {
      padding-left: 0.6em;
    }

    .badge-option {
      display: inline-flex;
    }
  }

  &__value-container {
    padding-bottom: 1px !important;
    padding-top: 1px !important;

    &-placeholder {
      display: none;

      &-top {
        padding-bottom: 6px !important;
        padding-top: 20px !important;

        .form-custom-select__value-container-placeholder {
          font-size: 12px;
          margin: 0;
          display: inline;
          color: $input-placeholder-color !important;

          @include position(absolute, 3px auto aut 10px);
        }
      }
    }
  }

  &__input {
    &-container {
      margin: 0 !important;
      padding-bottom: 0 !important;
      padding-top: 0 !important;
    }
  }

  &__indicator-separator {
    background-color: $input-border-color;
  }

  &__indicator {
    color: $form-select-indicator-color !important;
    padding-bottom: 5px !important;
    padding-top: 5px !important;
    padding-left: 0 !important;
    margin-left: 0 !important;
    font-size: 16px;

    svg {
      margin: 0 -0.1875em;
      cursor: pointer !important;
    }

    &:hover {
      color: var(--primary) !important;
    }

    &-separator {
      margin-right: 13px!important;
    }
  }

  &__loading-indicator--spinner {
    display: inline-block;
    @include position(absolute, auto 5px auto auto);
  }

  &__clear-indicator {
    margin-left: 0 !important;
  }

  &__clear-indicator:hover {
    color: $red !important;
  }

  &__placeholder {
    color: $input-placeholder-color !important;
    white-space: nowrap !important;
  }

  &__menu {
    z-index: 1000 !important;

    &-list {
      @extend .custom-scroll;
    }

    &-portal {
      z-index: 10001 !important;
    }
  }

  &__multi-value {
    background-color: $blue-light !important;
    color: $body-color !important;
    border-radius: 7px !important;

    &__label {
      color: $body-color !important;
    }

    &__remove {
      @include hover {
        border-radius: 7px !important;
        background: theme-primary("600") !important;
        color: $white !important;
      }
    }

    &--without-bg {
      background-color: unset !important;
      color: initial !important;

      .form-custom-select__multi-value__remove, .form-custom-select-creatable__multi-value__remove {
        padding-left: 0 !important;
        padding-right: 0 !important;

        &:hover {
          background: unset !important;
          color: theme-primary() !important;
        }
      }
    }
  }

  &__option {
    &--is-focused {
      background-color: $gray-200 !important;
    }

    &--is-selected {
      color: theme-primary("500") !important;
      background-color: theme-primary("300") !important;
    }
  }

  &__group-heading {
    text-transform: initial!important;
    color: $gray-700!important;
    font-weight: initial!important;
  }

  &--is-disabled div[class$='control'] {
    background-color: $input-disabled-bg !important;
    border-color: $input-border-color !important;
  }

  &--is-invalid div[class$='control'] {
    border-color: $red !important;
  }
}

// styles for select with different color schemes for statuses and filters
.form-inline-custom-select-wrap, .form-block-custom-select-wrap {
  .form-custom-select,
  .form-custom-select-creatable {
    &__control {
      &--without-border {
        border: unset !important;
        background-color: unset !important;
        margin-left: -8px !important;
      }

      &--small {
        min-height: $input-line-height * 1em !important;
      }

      &--pointer {
        cursor: pointer;
      }

      &:hover :not(:disabled) {
        .form-custom-select__indicator {
          color: var(--primary) !important;
        }
      }
    }

    &__single-value {
      &--primary-color {
        color: theme-primary() !important;
      }

      &--is-disabled {
        opacity: $input-disabled-opacity !important;
      }
    }

    &__single-value {
      &--active-color, &--inactive-color {
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        font-size: 12px;
      }

      &--bold {
        font-weight: $font-weight-bold;
      }

      .form-custom-select__option--check {
        display: none;
      }
    }

    &__option {
      cursor: pointer !important;

      &--title {
        font-size: 11px;
        line-height: 11px;
      }

      &--justify-center {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &--check {
        flex: none;
        margin-left: 8px;
      }

      &--is-selected {
        color: inherit !important;
        background-color: unset !important;
      }
    }

    &--is-disabled div[class$='control'] {
      background-color: $input-disabled-bg !important;

      &.form-custom-select__control--without-border {
        background-color: inherit !important;
      }

      .form-custom-select__indicators {
        opacity: $input-disabled-opacity !important;
      }
    }
  }
}

.form-inline-custom-select-wrap {
  display: inline-block;
}


.form-inline-custom-select-wrap {
  display: inline-block;
}
