.avatar {
  &:hover {
    text-decoration: none;
  }

  &--active {
    .avatar__picture {
      background-color: theme-primary("400");
    }

    .avatar__initials {
      color: theme-primary();
    }
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $gray-300;
    border-radius: 50%;
    user-select: none;
    overflow: hidden;

    @include size(100%);
  }

  &__square {
    border-radius: 0;
  }

  &__initials {
    text-transform: uppercase;
    text-overflow: ellipsis;
    text-align: center;
    overflow: hidden;
    font-weight: 700;
    color: $gray-500;
    max-width: 100%;
    margin: 0 6px;
  }

  &__image {
    display: block;
    object-fit: cover;

    @include size(100%);
  }
}
