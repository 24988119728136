.dropdown-menu {
  position: absolute;
  z-index: $zindex-dropdown;
  display: none; // none by default, but block on "open" of the menu
  min-width: $dropdown-min-width;
  padding: $dropdown-padding-y $dropdown-padding-x;
  margin: 0; // Override default margin of ul
  font-size: $dropdown-font-size;
  color: $dropdown-color;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  list-style: none;
  background-color: $dropdown-bg;
  background-clip: padding-box;
  border: $dropdown-border-width solid $dropdown-border-color;

  @include border-radius($dropdown-border-radius);

  &[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: $dropdown-spacer;
  }
}

// Dividers (basically an `<hr>`) within the dropdown
.dropdown-divider {
  height: 0;
  margin: $dropdown-divider-margin-y 0;
  overflow: hidden;
  border-top: 1px solid $dropdown-divider-bg;
}

// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item {
  display: flex;
  align-items: center;
  width: 100%; // For `<button>`s
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  clear: both;
  font-weight: $font-weight-normal;
  color: $dropdown-link-color;
  text-align: inherit; // For `<button>`s
  text-decoration: if($link-decoration == none, null, none);
  white-space: nowrap; // prevent links from randomly breaking onto new lines
  background-color: transparent; // For `<button>`s
  border: 0; // For `<button>`s

  // Prevent dropdown overflow if there's no padding
  // See https://github.com/twbs/bootstrap/pull/27703
  @if $dropdown-padding-y == 0 {
    &:first-child {
      @include border-top-radius($dropdown-inner-border-radius);
    }

    &:last-child {
      @include border-bottom-radius($dropdown-inner-border-radius);
    }
  }

  &:hover,
  &:focus {
    text-decoration: if($link-hover-decoration == underline, none, null);
    color: $dropdown-link-hover-color;
    background-color: $dropdown-link-hover-bg;
    outline: none;
  }

  &.active,
  &:active {
    color: $dropdown-link-active-color;
    background-color: $component-active-bg;
    text-decoration: none;
  }

  &.disabled,
  &:disabled {
    color: $dropdown-link-disabled-color;
    pointer-events: none;
    background-color: transparent;

    .icons {
      color: $dropdown-link-disabled-color;
    }
  }
}

.dropdown-menu.show {
  display: block;
}

// Dropdown section headers
.dropdown-header {
  display: block;
  padding: $dropdown-header-padding;
  margin-bottom: 0; // for use with heading elements
  font-size: $font-size-s;
  color: $dropdown-header-color;
  white-space: nowrap; // as with > li > a
}

// Dropdown text
.dropdown-item-text {
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  color: $dropdown-link-color;
}

// Dropdown actions with icon
.dropdown-item-icon-text {
  font-size: 14px;

  .icons {
    margin-right: 8px;
  }
}

// Dropdown toggle
//.dropdown-toggle::after {
//  display: inline-block;
//  margin-left: 0.255em;
//  vertical-align: 0.155em;
//  content: "";
//  border-top: 0.3em solid;
//  border-right: 0.3em solid transparent;
//  border-bottom: 0;
//  border-left: 0.3em solid transparent;
//}
