.nav {
  width: 100%;
  display: inline-flex;
  overflow-x: auto;
  padding: 5px 0 0;
  margin-bottom: 20px;
  margin-top: -5px;

  @extend .custom-scroll;

  &-item {
    position: relative;
    display: inline-block;
    font-size: 16px;
    color: $gray-500;
    margin-right: 16px;
    white-space: nowrap;
    letter-spacing: -0.32px;
    line-height: 22px;
    padding-bottom: 6px;
    cursor: pointer;

    &__selected {
      color: theme-primary();
      border-bottom: 2px solid theme-primary();
    }

    &__badge {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: theme-color("danger");
      border-radius: 16px;
      padding: 1px 5px;
      color: #fff;
      position: absolute;
      z-index: 2;
      right: 0;
      top: 0;
      transform: translate(50%, -25%);
      height: 20px;
      min-width: 20px;
      font-size: 12px;
      font-weight: 600;
      line-height: 11px;
    }
  }

  &:last-child {
    margin-right: unset;
  }

  &__content {
    display: none;

    .thread-item {
      border: unset;
      margin-bottom: unset;
    }

    &--active {
      display: block;
    }

    &--body {
      padding: 16px;

      .box-header {
        border-bottom: $border;
        margin: 0 -16px 8px;
        padding: 0 16px;
      }
    }
  }
}

.tab-content {
  > .tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}

