.pagination {
  display: flex;

  @include list-unstyled;
}

.page-link {
  display: block;
  position: relative;
  text-decoration: if($link-decoration == none, null, none);
  border: $pagination-border-width solid $pagination-border-color;
  background-color: $pagination-bg;
  color: $pagination-color;

  @include transition($pagination-transition);

  &-previous,
  &-next {
    color: $gray-700;
  }

  &:hover {
    text-decoration: if($link-hover-decoration == underline, none, null);
    border-color: $pagination-hover-border-color;
    background-color: $pagination-hover-bg;
    color: $pagination-hover-color;
    z-index: 2;
  }

  &:focus {
    background-color: $pagination-focus-bg;
    outline: $pagination-focus-outline;
    color: $pagination-focus-color;
    z-index: 3;
  }
}

.page-item {
  &:not(:first-child) .page-link {
    margin-left: $pagination-margin-start;
  }

  &.selected .page-link {
    border-color: $pagination-active-border-color;
    background-color: $pagination-active-bg;
    color: $pagination-active-color;
    font-weight: 700;
    z-index: 3;
  }

  &.disabled .page-link {
    background-color: $pagination-disabled-bg;
    border-color: $pagination-disabled-border-color;
    color: $pagination-disabled-color;
    opacity: $pagination-opacity;
    pointer-events: none;
  }
}

//
// Sizing
//
@include pagination-size($pagination-padding-y, $pagination-padding-x, null, $pagination-border-radius);
