// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.badge {
  display: inline-block;
  padding: $badge-padding-y $badge-padding-x;
  font-size: $badge-font-size;
  font-weight: $badge-font-weight;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  @include border-radius($badge-border-radius);
  @include transition($badge-transition);

  @at-root a#{&} {
    @include hover-focus() {
      text-decoration: none;
    }
  }

  // Empty badges collapse automatically
  &:empty {
    display: none;
  }
}

// Quick fix for badges in buttons
.btn .badge {
  position: relative;
  top: -1px;
}

// Pill badges
//
// Make them extra rounded with a modifier to replace v3's badges.

.badge-pill {
  padding-right: $badge-pill-padding-x;
  padding-left: $badge-pill-padding-x;
  @include border-radius($badge-pill-border-radius);
}

// Colors
//
// Contextual variations (linked badges get darker on :hover).

@each $color, $value in $badge-colors {
  .badge-#{$color} {
    @include badge-variant($value);
  }
}

// Options
//
// Make badge for options filter and select
.badge-option {
  padding: 4px;
  font-weight: $font-weight-normal;
  display: flex;
  align-items: center;
  font-size: 13px;

  .icons {
    margin-right: 4px;
    flex: none;
  }

  span {
    font-size: 12px;
    line-height: 11px;
    color: $gray-700;
    letter-spacing: -0.078px;
    vertical-align: middle;
  }
}
