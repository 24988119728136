//
// Check/radio
//

.form-check {
  display: block;
  padding-left: $form-check-padding-start;
  margin-bottom: $form-check-margin-bottom;
  min-height: $form-check-min-height;

  .form-check-input {
    float: left;
    margin-left: $form-check-padding-start * -1;
  }
}

.form-check-input {
  border: $form-check-input-border;
  background-color: $form-check-input-bg;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  vertical-align: top;
  appearance: none;
  color-adjust: exact; // Keep themed appearance for print
  margin-top: 2px;

  @include transition($form-check-transition);
  @include size($form-check-input-width);

  &[type="checkbox"] {
    @include border-radius($form-check-input-border-radius);
  }

  &[type="radio"] {
    // stylelint-disable-next-line property-disallowed-list
    border-radius: $form-check-radio-border-radius;
  }

  &:active {
    filter: $form-check-input-active-filter;
  }

  &:focus {
    outline: 0;
  }

  &:checked {
    background-color: $form-check-input-checked-bg-color;
    border-color: $form-check-input-checked-border-color;
    border-width: 2px;

    &[type="checkbox"] {
      background-image: escape-svg($form-check-input-checked-bg-image);
    }

    &[type="radio"] {
      background-image: escape-svg($form-check-radio-checked-bg-image);
    }
  }

  &[type="checkbox"]:indeterminate {
    background-color: $form-check-input-indeterminate-bg-color;
    border-color: $form-check-input-indeterminate-border-color;
    background-image: escape-svg($form-check-input-indeterminate-bg-image);
  }

  &:disabled {
    pointer-events: none;
    filter: none;
    opacity: $form-check-input-disabled-opacity;
  }

  // Use disabled attribute in addition of :disabled pseudo-class
  &[disabled],
  &:disabled {
    ~ .form-check-label {
      opacity: $form-check-label-disabled-opacity;
    }
  }
}

.form-check-label {
  cursor: $form-check-label-cursor;
  color: $form-check-label-color;
  font-size: $font-size-base;
  margin-bottom: 0;
}

//
// Switch
//

.form-switch {
  padding-left: $form-switch-padding-start;

  .form-check-input {
    margin: 0 0 0 $form-switch-padding-start * -1;
    background-image: escape-svg($form-switch-bg-image);
    background-color: $form-switch-bg;
    background-position: left center;
    border-color: $form-switch-bg;

    @include hover {
      background-color: $form-switch-bg-hover;
      border-color: $form-switch-bg-hover;
    }

    @include border-radius($form-switch-border-radius);
    @include transition($form-switch-transition);
    @include size($form-switch-width, 20px);

    &:checked {
      background-position: $form-switch-checked-bg-position;
      background-image: escape-svg($form-switch-bg-image);
      background-color: theme-primary();
      border-color: theme-primary();

      &:hover {
        background-color: theme-primary("600");
        border-color: theme-primary("600");
      }
    }
  }
}

.form-check-inline {
  display: inline-block;
  margin-right: $form-check-inline-margin-end;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;

  &[disabled],
  &:disabled {
    + .btn {
      pointer-events: none;
      filter: none;
      opacity: $form-check-btn-check-disabled-opacity;
    }
  }
}
