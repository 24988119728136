.form {
    &-paragraph {
        max-width: 840px;
    }

    &-badge {
        padding: 8px 14px;
        display: flex;
        align-items: flex-start;
        gap: 8px;

        .icons {
            margin-top: 2px;
            margin-right: 0;
            width: 18px;
            height: 18px;
            min-width: 18px;
            font-size: 16px;
        }

        &-content {
            max-width: 960px;
            font-size: 14px;
            white-space: normal;
            text-align: left;
            line-height: 22px;
            letter-spacing: -0.24px;
        }

        &__title {
            font-weight: 600;
        }
        &__description {
            margin-top: 4px;
            padding-bottom: 16px;
        }
        &__collapse-btn {
            text-decoration: underline;
        }
    }

    &-result-block {
        padding: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        background-color: $gray-200;

        &--error {
            background-color: $red-light;
        }

        &__title {
            padding-bottom: 16px;
            font-size: 18px;
            font-weight: 600;
            line-height: 22px;
        }
        &__description {
            max-width: 700px;
            font-size: 16px;
            line-height: 22px;

            .form-check-label {
                font-size: 15px;
            }
        }
        &__logo {
            margin-top: 40px;
            max-width: 120px;
            height: 30px;
            img {
              display: block;
              object-fit: contain;
      
              @include size(100%);
            }
        }
    }

    &-input-block {
        padding: 16px;
        background-color: $gray-200;

        &-inner {
            max-width: 840px;
        }

        &__title {
            font-size: 18px;
            font-size: 16px;
            font-weight: 600;
        }
        &__description {
            line-height: 22px;
        }
        ul {
            margin: 0;
            padding-left: 16px;
        }
        .form-group {
            .form-check {
                margin-top: 14px;
            }   
        }
    }

    &-photos {
        margin-top: 12px;
        margin-bottom: 12px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        &__item {
            display: flex;
            align-items: center;
            gap: 16px;
        }
        &__remove {
            font-size: 14px;
            font-weight: normal;
            color: $danger;
        }
    }
}

.select-location {
    .page-main {
        overflow: visible;
    }

    .form-custom-select__control {
        min-height: 44px;
    }

    .locations-select {
        &-option {
            display: flex;
            align-items: center;
        
            &__address {
                font-weight: $font-weight-normal;
                font-size: 12px;
                color: $gray-500;
            }
        
            .icons {
                margin-right: 8px;
                flex: none;
                font-size: 24px;
            }
        
            > div {
                flex: 1;
                width: 100%;
                white-space: normal;
            }
        }
    }
}

.services {
    &-list {
        flex: 1;
        padding-bottom: 24px;

        &--loading {
            padding: 56px 0;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &--empty {
            padding: 56px 0;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            background-color: $gray-200;
            color: $gray-500;
        }

        .service-item {
            margin-bottom: 24px;
            padding: 16px;
            border-radius: 4px;
            background-color: $blue-light;
            cursor: pointer;

            @include media-breakpoint-down(sm, $breakpoints) {
                margin-bottom: 12px;
            }

            &__title {
                font-size: 16px;
                font-weight: 600;
            }
            &__description {
                margin-top: 8px;
                max-width: 700px;
                font-size: 15px;
                line-height: 22px;
            }
        }
    }
}

.patient-signin {
    &-form {
        padding: 24px;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        background-color: $gray-200;

        &-nhs {
            display: flex;
            align-items: center;
            gap: 16px;
            padding-top: 16px;
            border-top: $border;
        }
    }
}

.continue-as-guest {
    .guest-form {
        padding: 24px;
        border-radius: 4px;
        background-color: $gray-200;
        display: grid;
        grid-template-columns: 1fr 1fr; 
        gap: 16px;

        @include media-breakpoint-down(sm, $breakpoints) {
            grid-template-columns: 1fr;
            padding: 16px;
            padding-bottom: 24px;
        }
    }
}

.travel-destination-form {
    .form-input-block-inner {
        max-width: 100%;
    }

    &-content {
        max-width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr; 
        gap: 16px;
    
        @include media-breakpoint-down(sm, $breakpoints) {
            grid-template-columns: 1fr;
            padding-bottom: 24px;
        }
    }
}

.form-header {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.form-questions {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &-grid {
        max-width: 960px;
        display: grid;
        grid-template-columns: 1fr 1fr; 

        @include media-breakpoint-down(sm, $breakpoints) {
            grid-template-columns: 1fr;
        }
    }

    &-separator {
        margin: 16px 0;
        width: 100%;
        grid-column: 1 / -1;
        border-top: $border;
    }
}

.form-question {
    padding: 16px;
    max-width: 600px;
    background-color: $gray-200;

    &-header {
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &--radio {
        .form-question-body {
            display: flex;
            flex-direction: column;
            gap: 4px;
        }
    }

    &--checkboxes {
        .form-question-body {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
    }

    &--binary {
        .form-question-body {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
        .form-question-title {
            display: flex;
            align-items: center;
            gap: 32px;
        }
    }
}

.form-submit {
    &__link {
        color: $primary;
        text-decoration: underline;
    }
}
