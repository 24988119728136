.btn {
  display: inline-block;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  color: $gray-700;
  text-align: center;
  text-decoration: if($link-decoration == none, null, none);
  white-space: $btn-white-space;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: $btn-border-width solid transparent;
  -webkit-appearance: none;

  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
  @include transition($btn-transition);
  @include hover {
    background-color: $gray-200;
    text-decoration: none;
    color: $gray-600;
  }


  &:focus,
  &.focus {
    background-color: transparent;
    outline: 0;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
  }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

//
// Alternate buttons
//

@each $color, $value in $theme-colors {
  @if $color == primary {
    .btn-#{$color} {
      @include button-variant-primary($value, $value, var(--primary-text-color), theme-primary("600"));
    }
  }
  @else {
    .btn-#{$color} {
      @include button-variant($value, $value);
    }
  }
}

@each $color, $value in $theme-colors {
  @if $color == primary {
    .btn-outline-#{$color} {
      @include button-outline-variant-primary($value, $white, theme-primary("600"));
    }
  }
  @else {
    .btn-outline-#{$color} {
      @include button-outline-variant($value);
    }
  }
}

//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  text-decoration: $link-decoration;
  font-weight: $font-weight-normal;
  color: $link-color;

  @include hover {
    text-decoration: $link-hover-decoration;
    background-color: $link-hover-background;
    color: $link-hover-color;
  }

  &:focus,
  &.focus {
    background-color: $link-active-background;
    text-decoration: $link-hover-decoration;
    color: $link-hover-color;
  }
  // No need for an active state here
}

//
// Icon buttons
//
.btn-icon {
  border-radius: $btn-icon-border-radius;
  display: inline-flex;
  align-items: center;
  height: calc(1em + 14px);
  padding: 1px 6px 1px;
  font-size: 18px;

  @each $color, $value in $theme-colors {
    @if $color == primary {
      &.btn-#{$color} {
        color: var(--primary-text-color) !important;
      }
    }
    @else {
      &.btn-#{$color} {
        color: color-yiq($value, $black, $white) !important;
      }
    }
  }

  &.btn-link {
    background-color: transparent;
    border-color: transparent;

    @each $color, $value in $theme-colors {
      @if $color == primary {
        &.btn-#{$color} {
          color: var(--primary) !important;
        }
      }
      @else {
        &.btn-#{$color} {
          color: $value !important;
        }
      }
    }

    &:hover {
      background-color: transparent;
    }
  }

  &.btn-lg {
    border-radius: $btn-icon-border-radius;
    height: calc(1em + 18px);
    padding: 0 8px;
    font-size: 24px;
  }

  &.btn-sm {
    border-radius: $btn-icon-border-radius;
    height: calc(1em + 4px);
    padding: 0 2px;
    font-size: 12px;
  }

  &-no-shadows{
    box-shadow: unset;
    border: unset;

    &:active {
      box-shadow: unset;
      border: unset;
      opacity: 0.8;
    }
  }

  & .icons {
    outline: unset;
  }
}

//
// Without shadow buttons
//

.btn-no-shadow {
  background-color: transparent;
  border-color: transparent;
  padding: unset;

  @each $color, $value in $theme-colors {
    @if $color == primary {
      &.btn-#{$color} {
        color: var(--primary) !important;
      }
    }
    @else {
      &.btn-#{$color} {
        color: $value !important;
      }
    }
  }

  &:hover, &:focus, &:active {
    background-color: transparent !important;
    border-color: transparent !important;
  }
}

//
// Status button
//
.btn-status {
  padding: 5px 10px;
  display: inline-block;

  @include button-size(5px, 10px, 15px, 16px, 100px);
}

//
// Button Sizes
//

.btn-lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
}

.btn-sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
}

//
// Block button
//

.btn-block {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}

//
// Add something with <Button><Icon /><span>text</span></Button>
//

.btn.text-link {
  padding: 0;
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }
}

.btn.add {
  padding: 1px 0;
  font-size: $font-size-s;
  text-decoration: none;

  span {
    margin-left: 4px;
    font-size: 13px;
  }
}

.nhslogin-button {
  font-weight: 500 !important;
  font-size: 16px;
  outline: none !important;
  line-height: 1.47368;
  -webkit-appearance: none;
  appearance: none;
  background-color: #005eb8;
  border: 2px solid transparent;
  border-radius: 4px;
  box-shadow: 0 4px 0 #003087;
  color: #fff;
  font-weight: 600;
  padding: 8px 16px;
  position: relative;
  text-align: center;
  vertical-align: top;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #1e569b;
  }
  &:active {
    background: #1e569b;
    box-shadow: none;
    color: #fff;
    top: 4px;
  }
  &__logo {
    padding-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
