.drawer {
  display: flex;
  flex-direction: column;
  box-shadow: $drawer-box-shadow;
  background-color: $drawer-background;
  min-height: 0;
  z-index: 901;

  @include position(absolute, 0 0 0 null);
  @include size($drawer-width, 100%);

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 $drawer-horizontal-padding;
    background-color: $white;
    height: 60px;

    &__left, &__right {
      display: inline-flex;
      align-items: center;

      & > * {
        margin-right: $drawer-header-horizontal-gap;

        &:last-child {
          margin-right: 0;
        }
      }

      .btn-icon {
        padding: 0;
        color: inherit;
      }
    }

    h1,
    h2 {
      font-size: 16px;
      margin-bottom: 0;
    }
  }

  &-subheader {
    padding: 0 $drawer-horizontal-padding;
  }

  &-body {
    padding: 13px $drawer-horizontal-padding;
    overflow-x: hidden;
    flex: 1 1 calc(100% - 60px);
    height: calc(100% - 60px);

    @extend .custom-scroll;

    &__group {
      background-color: $white;
      border-radius: $border-radius;
      border: $border;
      margin-bottom: 16px;

      &--edit {
        padding: 16px;

        & > *:last-child {
          margin-bottom: 0;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-footer {
    border-top: $border-width solid $border-color;
    padding: 25px 20px;
  }

  & > form {
    height: 100%;
  }
}
