/* _button-group.scss:41 */
.input-group {
  width: auto;
}

/* _input-group.scss:7 */
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap; // For form validation feedback
  align-items: stretch;
  width: 100%;

  > .form-control,
  > .custom-select,
  > .custom-file {
    position: relative; // For focus state's z-index
    flex: 1 1 auto;
    // Add width 1% and flex-basis auto to ensure that button will not wrap out
    // the column. Applies to IE Edge+ and Firefox. Chrome does not require this.
    width: 1%;
    margin-bottom: 0;

    + .form-control,
    + .custom-select,
    + .custom-file {
      margin-left: -$input-border-width;
    }
  }

  // Bring the "active" form control to the top of surrounding elements
  > .form-control:focus,
  > .custom-select:focus,
  > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3;
  }

  // Bring the custom file input above the label
  > .custom-file .custom-file-input:focus {
    z-index: 4;
  }

  > .form-control,
  > .custom-select {
    &:not(:last-child) { padding-right: 30px; }
    &:not(:first-child) { padding-left: 30px; }
  }

  // Custom file inputs have more complex markup, thus requiring different
  // border-radius overrides.
  > .custom-file {
    display: flex;
    align-items: center;

    &:not(:last-child) .custom-file-label,
    &:not(:last-child) .custom-file-label::after { @include border-right-radius(0); }
    &:not(:first-child) .custom-file-label { @include border-left-radius(0); }
  }

  &-prepend, &-append {
    position: absolute;
    width: 30px;
    top: 0;
    bottom: 0;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 19px;
    color: $text-muted
  }

  &-prepend {
    left: 0;
    padding-left: 8px;
  }

  &-append {
    right: 0;
  }

  &-form {
    margin-bottom: 16px;

    .form-group {
      width: 100%;
      position: relative;
      margin-bottom: 0 !important;

      > .form-control {
        &:not(:last-child) {
          padding-right: 30px;
        }

        &:not(:first-child) {
          padding-left: 30px;
        }
      }
    }
  }
}

