.content-split {
  display: flex;
  min-height: 0;
  flex: 1;

  &-table {
    display: flex;
    flex-direction: column;
    min-height: 0;
    min-width: 0;
    flex: 1;

    + .content-split-view {
      border-left: $border-width solid $border-color;
    }
  }

  &-view {
    flex: 0 0 320px;
    min-width: 0;

    &-header {
      display: flex;
      justify-content: space-between;
      padding: 20px 10px 20px 20px;

      h3 {
        margin: 6px 0;
      }

      + .content-split-view-body {
        padding-top: 0;
      }
    }

    &-title {
      flex: 1;

      &--align, &--center, &--between {
        display: flex;
        align-items: center;
      }

      &--center {
        justify-content: center;
      }

      &--between {
        justify-content: space-between;
      }
    }

    &-actions {
      display: flex;
      align-items: flex-start;

      & > * {
        margin-left: 4px;
      }
    }

    &-body {
      padding: 20px;

      &--spinner {
        padding: 50px 20px !important;
      }
    }

    &-more {
      text-transform: uppercase;
      color: theme-primary();
      cursor: pointer;
      font-weight: $font-weight-bold;
    }
  }
}
