.navigation {
  padding: 0 18px;

  &-list {
    list-style: none;
    margin-bottom: 0;
    padding: 12px 0;

    li + li {
      margin-top: 4px;
    }

    & + .navigation-list {
      border-top: $border-width solid $border-color;
    }
  }

  &-link {
    display: flex;
    border-radius: 10px;
    align-items: center;
    background-color: transparent;
    transition: $btn-transition;
    color: $body-color;
    font-weight: 500;
    padding: 0 10px;
    min-width: 0;

    &:hover,
    &--active {
      background-color: theme-primary("400");
      color: theme-primary();

      .navigation-link__icon {
        color: theme-primary();
      }
      text-decoration: none;
    }

    &--active {
      font-weight: 700;
    }

    &--disabled {
      background-color: #fff;
      color: $disabled-color;
      pointer-events: none;
      cursor: not-allowed;
      user-select: none;

      .navigation-link__icon {
        color: $disabled-color;
      }

      .navigation-link__badge {
        background-color: $disabled-color;
      }
    }

    &__icon {
      transition: $btn-transition;
      padding: 4px 6px 4px 0;
      line-height: 24px;
      color: $gray-700;
      font-size: 24px;
    }

    &__label {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding: 6px 0;
      flex: 1;

      &--tools {
        padding: 6px 0;
      }
    }

    &__badge {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: theme-color("danger");
      border-radius: 16px;
      line-height: 10px;
      padding: 1px 5px;
      font-weight: 700;
      font-size: 10px;
      min-width: 16px;
      color: #fff;
      height: 16px;
    }
  }
}
