// Pagination

@mixin pagination-size($padding-y, $padding-x, $font-size, $border-radius) {
  .page-link {
    padding: $padding-y $padding-x;
    font-size: $font-size;

    &-previous {
      line-height: 21px;
      font-size: 20px;
      padding: 0 1px;
    }

    &-next {
      line-height: 21px;
      font-size: 20px;
      padding: 0 1px;
    }
  }

  .page-item {
    @if $pagination-margin-start == -$pagination-border-width {
      &:first-child {
        .page-link {
          @include border-left-radius($border-radius);
        }
      }

      &:last-child {
        .page-link {
          @include border-right-radius($border-radius);
        }
      }
    }
    @else {
      //Add border-radius to all pageLinks in case they have left margin
      .page-link {
        @include border-radius($border-radius);
      }
    }
  }
}
