.icons {
  display: inline-block;
  stroke: currentColor;
  fill: currentColor;
  stroke-width: 0;
  vertical-align: -0.125em;

  @include size(1em);

  &-big-width {
    @include size(1.25em, 1em);
  }

  &-toast {
    @include size(24px);

    @each $color, $value in $theme-colors {
      @if $color == danger {
        &-error {
          color: $value !important;
        }
      }
      @if $color == warning {
        &-warn {
          color: $value !important;
        }
      }
      @else {
        &-#{$color} {
          color: $value !important;
        }
      }
    }
  }

  &.nhs {
    @include size(2.6em, 1em);
  }
}
