.box {
  padding: 16px;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-title {
    h1, h2, h3, h4, h5 {
      margin-bottom: 0;
    }
  }

  &-body {
  }

  &-footer {
  }

  &-action {
  }
}
