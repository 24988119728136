:root {
  --primary: hsl(216deg 100% 41%);
  --primary-lighten: hsl(216deg 100% 41% / 4%);
  --primary-light: hsl(216deg 100% 41% / 8%);
  --primary-darken: hsl(216deg 100% calc(41% * 0.7));
  --primary-dark: hsl(216deg 100% calc(41% * 0.85));
  --primary-text-color: #fff;

  /*TODO Sort for useful fullcalendar variables*/
  --fc-small-font-size: .85em;
  --fc-page-bg-color: #fff;
  --fc-neutral-bg-color: $gray-300;
  --fc-neutral-text-color: $gray-200;
  --fc-border-color: transparent;

  --fc-button-text-color: #fff;
  --fc-button-bg-color: #2C3E50;
  --fc-button-border-color: #2C3E50;
  --fc-button-hover-bg-color: #1e2b37;
  --fc-button-hover-border-color: #1a252f;
  --fc-button-active-bg-color: #1a252f;
  --fc-button-active-border-color: #151e27;

  --fc-event-bg-color: #{$secondary};
  --fc-event-border-color: transparent;
  --fc-event-text-color: #{$body-color};
  --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);

  --fc-more-link-bg-color: #d0d0d0;
  --fc-more-link-text-color: inherit;

  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;

  --fc-non-business-color: rgba(215, 215, 215, 0.3);
  --fc-bg-event-color: rgb(143, 223, 130);
  --fc-bg-event-opacity: 0.3;
  --fc-highlight-color: rgba(188, 232, 241, 0.3);
  --fc-today-bg-color: #{$gray-200};
  --fc-now-indicator-color: red;

  --toastify-color-info: #{$info};
  --toastify-color-success: #{$success};
  --toastify-color-warning: #{$warning};
  --toastify-color-error: #{$danger};
  --toastify-color-initial: #{$initial};
}
