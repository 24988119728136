.row-icon {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__icon {
    flex: 0 0 1.9em;
    max-width: 1.9em;
    text-align: center;
  }

  &__content {
    flex: 0 0 calc(100% - 2em - 8px);
    max-width: calc(100% - 2em - 8px);
  }
}
