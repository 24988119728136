.filter-search {
  display: inline-block;
  position: relative;

  &--hidden {
    display: none;
  }

  &-input {
    min-width: 283px;
    padding-right: 29px;
    caret-color: theme-primary();

    ::placeholder, ::-moz-placeholder {
      color: $gray-400;
    }
  }

  &__icon {
    &-close {
      font-size: 24px;
      margin: auto;
      color: $gray-400;
      align-items: center;
      display: flex;

      @include position(absolute, 0 3px 0 null);

      @include hover {
        color: theme-primary();
        cursor: pointer;
      }
    }
  }

  &__option {
    display: flex;
    align-items: center;
    max-width: 100%;

    .icons {
      flex: none;
      font-size: 20px;
      color: $gray-500;
    }

    &-name {
      flex: none;
      font-size: 14px;
      margin: 0 10px;
      color: $gray-500;
    }

    &-value {
      flex: 1;
      font-size: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .form-custom-select {
    &__control {
      width: 280px;
      min-height: 32px !important;

      &--is-focused {
        .form-custom-select__input {
          min-width: auto;
        }
      }
    }

    &__placeholder {
      display: none;
    }

    &__indicators {
      width: 25px;
    }

    &__group-heading {
      text-transform: none !important;
      font-size: 12px !important;
    }

    &__multi-value {
      &__label {
        .filter-search__option {
          &-name {
            display: none;
          }

          &-value {
            margin-left: 5px;
          }

          .icons {
            color: $primary-text-color !important;
          }
        }
      }
    }

    &__option {
      cursor: pointer;

      &--is-focused {
        background-color: theme-primary() !important;

        .filter-search__option {
          .icons, &-name, &-value {
            color: $primary-text-color !important;
          }
          &-name {
            opacity: 0.5;
          }
        }
      }
    }
  }
}
