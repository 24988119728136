.fallback-error {
  padding: 12px;

  pre {
    white-space: inherit;
  }
}

.suspense-loader {
  position: relative;
  width: 100%;
  height: 100%;
}
