@mixin text-emphasis-variant($parent, $color, $ignore-warning: false) {
  #{$parent} {
    color: $color !important;
  }

  @if $emphasized-link-hover-darken-percentage != 0 {
    a#{$parent} {
      @include hover-focus {
        color: darken($color, $emphasized-link-hover-darken-percentage) !important;
      }
    }
  }
}

@mixin text-emphasis-primary-variant($parent, $color, $hover: theme-primary("600")) {
  #{$parent} {
    color: $color !important;
  }

  a#{$parent} {
    @include hover-focus {
      color: $hover !important;
    }
  }
}
